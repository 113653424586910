import { observations } from "@compono/assessments-kit";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogCustomHeader,
  DialogPrimaryActions,
  Divider,
  Flex,
  Heading,
  Inline,
  LayoutScreen,
  Link,
  Loader,
  Logo,
  Select,
  Stack,
  Switch,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsList,
  Text,
  TextInput,
} from "@compono/ui";
import React, { useEffect, useState } from "react";
import { useApolloClients } from "~/components/ApolloClients";
import { useSearchParams } from "~/hooks/useSearchParams";
import { useCultureReportContentLazyQuery } from "~/lib/federationGateway/publicGraphql";
import { AssessmentType } from "../../../src/types";
import CampaignCompletionCard from "../../components/CampaignCompletionCard/CampaignCompletionCard";
import { formatMonth } from "../../lib/formattingHelpers";
import { BreakdownContent } from "../Culture/Breakdown/BreakdownContent";
import { Filter } from "../Culture/Breakdown/CultureBreakdown";
import { EngagementContent } from "../Culture/Engagement";
import { Heatmap } from "../Culture/Hotspots/Heatmap";
import EventsList from "../Culture/Report/EventsList";
import ReportGraph from "../Culture/Report/ReportGraph/ReportGraph";
import {
  cultureDimensionsInsights,
  reportCardStyle,
  titleContainerStyle,
} from "../Culture/Report/styles";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import NotFoundImage from "../../assets/images/not-found-image";

import { CloudDownloadOutlineIcon } from "@compono/ui-icons";
import { ReactComponent as FeedbackSVG } from "../../assets/images/feedback.svg";
import { ReactComponent as LinkExpired } from "../../assets/images/link_expired.svg";
const getFilterLabel = (filterType) => {
  switch (filterType) {
    case "divisions":
      return "Division";
    case "officeLocation":
      return "Location";
    case "jobLevel":
      return "Job Level";

    default:
      break;
  }
};
// constants
export const dimensions = [
  { engagekey: "riskTolerance", key: "innovative|cautious" },
  { engagekey: "driver", key: "process|outcome" },
  { engagekey: "focus", key: "task centred|employee centred" },
  { engagekey: "teamWork", key: "collective|individual" },
  { engagekey: "control", key: "centralised control|delegated control" },
  {
    engagekey: "structure",
    key: "hierarchical structure|horizontal structure",
  },
  { engagekey: "responsivePlanned", key: "ad-hoc|planned" },
  { engagekey: "conformity", key: "conformity|individuality" },
  { engagekey: "accepting", key: "accepting|questioning" },
  { engagekey: "cooperation", key: "cooperation|competition" },
  { engagekey: "internalExternal", key: "external|internal" },
  { engagekey: "formal", key: "formalised|informal" },
];

const keyToEngageKey = (key: string): string => {
  const dimension = dimensions.find((dim) => dim.key === key);
  if (dimension) return dimension?.engagekey;
  return key;
};
const engageKeyToKey = (engagekey: string) => {
  const dimension = dimensions.find((dim) => dim.engagekey === engagekey);
  if (dimension) return dimension?.key;
  return engagekey;
};

const mapDimensions = (dims) => {
  return {
    results: {
      riskTolerance: [],
      driver: [],
      focus: [],
      teamWork: [],
      control: [],
      structure: [],
      responsivePlanned: [],
      conformity: [],
      accepting: [],
      cooperation: [],
      internalExternal: [],
      formal: [],
    },
    average: {
      riskTolerance: dims["innovative|cautious"],
      driver: dims["process|outcome"],
      focus: dims["task centred|employee centred"],
      teamWork: dims["collective|individual"],
      control: dims["centralised control|delegated control"],
      structure: dims["hierarchical structure|horizontal structure"],
      responsivePlanned: dims["ad-hoc|planned"],
      conformity: dims["conformity|individuality"],
      accepting: dims["accepting|questioning"],
      cooperation: dims["cooperation|competition"],
      internalExternal: dims["external|internal"],
      formal: dims["formalised|informal"],
    },
  };
};
const Header = ({ title = "" }) => {
  return (
    <Box
      sx={{
        width: "100%",
        background: "var(--colors-brandDark-100)",
        color: "white",
        p: 5,
        pl: "50px",
      }}
    >
      <Inline alignX="center">
        <Box sx={{ width: "1440px" }}>
          <Inline gap="9">
            <Logo tone="secondary"></Logo>
            <Text tone="light" align="center">
              {title}
            </Text>
          </Inline>
        </Box>
      </Inline>
    </Box>
  );
};
const CultureReport = () => {
  const [breakdownDataSet, setbreakdownDataSet] = useState<any>();
  const [filterType, setFiltertype] = useState<string>("");
  const [hotspotFilterType, setHotspotFiltertype] =
    useState<string>("divisions");
  const [showValues, setShowValues] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filter[]>();
  const [assessmentType, setAssessmentType] = useState(AssessmentType.Current);
  const [aggregates, setAggregates] = useState({});

  const [cutureReportData, setCutureReportData] = useState({});
  const [loading, setLoading] = useState(false);

  const [showReport, setShowReport] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [dialogHeader, setDialogHeader] = useState("Whole organisation");
  const [dialogOpen, setDialogOpen] = useState(false);

  const [currentResponses, setCurrentResponses] = useState(false);
  const [desiredResponses, setDesiredResponses] = useState(false);

  const [error, setError] = useState("");

  const { publicFederationGatewayClient } = useApolloClients();

  const [GetCultureReportContent] = useCultureReportContentLazyQuery({
    client: publicFederationGatewayClient,
    fetchPolicy: "cache-and-network",
  });
  const [searchParams] = useSearchParams();

  const pageId = searchParams.get("pageId");

  useEffect(() => {
    if (!loading && cutureReportData.breakdownData) {
      let newDivisionData = cutureReportData.breakdownData.divisionData;
      switch (filterType) {
        case "divisions":
          newDivisionData = cutureReportData.breakdownData.divisionData;
          break;
        case "officeLocation":
          newDivisionData = cutureReportData.breakdownData.locationData;
          break;
        case "jobLevel":
          newDivisionData = cutureReportData.breakdownData.jobLevelData;
          break;

        default:
          newDivisionData = cutureReportData.breakdownData.divisionData;
          break;
      }
      setbreakdownDataSet(newDivisionData);
      if (assessmentType === AssessmentType.Current)
        setFilters(newDivisionData.current);
      if (assessmentType === AssessmentType.Desired)
        setFilters(newDivisionData.desired);
    }
  }, [filterType]);
  const updateFilters = (filters: Filter[], breakdownFilters: Filter[]) => {
    return filters.map((filter) => {
      const breakdownFilter = breakdownFilters.find((bf) => {
        return filter.filterString === bf.filterString;
      });
      return { ...filter, values: breakdownFilter.values };
    });
  };

  useEffect(() => {
    if (breakdownDataSet) {
      const updatedFilters = updateFilters(
        filters,
        assessmentType === AssessmentType.Current
          ? breakdownDataSet.current
          : breakdownDataSet.desired
      );
      setFilters(updatedFilters);
    }
  }, [assessmentType]);

  if (cutureReportData.status === "Expired")
    return (
      <LayoutScreen sx={{ backgroundColor: "black.3" }}>
        <Stack>
          <Header></Header>
          <Inline alignX="center">
            <Box sx={{ mt: 8 }}>
              <Inline alignX="center">
                <LinkExpired />
              </Inline>
              <Text size="xxl" align="center" sx={{ mt: 6 }}>
                Sorry, the link has expired
              </Text>
              <Text>
                Please get in touch with the person who shared the link with you
                to get another one.
              </Text>
            </Box>
          </Inline>
        </Stack>
      </LayoutScreen>
    );

  const setDialogContent = (division: string, filter: string) => {
    const category = getFilterLabel(hotspotFilterType) + ": ";
    let dataSet = cutureReportData.hotspotData.orgData;
    if (division)
      switch (category) {
        case "Division: ": {
          dataSet = cutureReportData.hotspotData.divisionData.find(
            (div) => div.aggregation === division
          );

          break;
        }
        case "Location: ": {
          dataSet = cutureReportData.hotspotData.locationData.find(
            (loc) => loc.aggregation === division
          );

          break;
        }
        case "Job Level: ": {
          dataSet = cutureReportData.hotspotData.jobLevelData.find(
            (jlv) => jlv.aggregation === division
          );

          break;
        }

        default:
          break;
      }
    setCurrentResponses(mapDimensions(dataSet.current));
    setDesiredResponses(mapDimensions(dataSet.desired));
    setDialogHeader(
      division !== "" ? category + division : "Whole organisation"
    );
    setDialogOpen(true);
  };

  const loadData = async () => {
    setError("");
    setLoading(true);
    const { data } = await GetCultureReportContent({
      variables: {
        password: passcode,
        pageId,
      },
    });
    if (data.cultureReportContent.hotspotData) {
      setCutureReportData(data?.cultureReportContent);
      if (data?.cultureReportContent?.status === "Success") {
        setAggregates(data?.cultureReportContent.hotspotData?.divisionData);
        setFiltertype("divisions");
        setbreakdownDataSet(
          data?.cultureReportContent.breakdownData.divisionData
        );
        setFilters(
          data?.cultureReportContent.breakdownData.divisionData.current
        );
      }
    }
    if (data?.cultureReportContent.status === "Wrong_Password") {
      setError("Incorrect Passcode");
    } else {
      setShowReport(true);
    }
    setLoading(false);
  };
  if (loading && !breakdownDataSet)
    return (
      <LayoutScreen sx={{ backgroundColor: "black.3" }}>
        <Stack>
          <Header></Header>
          <Inline gap="9" alignX="center">
            <Box sx={{ mt: 8 }}>
              <Loader size="lg" label={"Loading"}></Loader>
            </Box>
          </Inline>
        </Stack>
      </LayoutScreen>
    );

  if (!showReport || cutureReportData.status === "Wrong_password")
    return (
      <LayoutScreen sx={{ backgroundColor: "black.3" }}>
        <Dialog
          isOpen={true}
          aria-describedby="dialog-description"
          aria-labelledby="dialog-title"
        >
          <DialogCustomHeader>
            <Logo></Logo>
          </DialogCustomHeader>
          <DialogBody id="dialog-description">
            <TextInput
              type="password"
              label="Passcode"
              onChange={({ target }) => {
                setPasscode(target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loadData();
                }
              }}
              error={error}
            ></TextInput>
          </DialogBody>
          <DialogPrimaryActions>
            <Button onClick={loadData}>Show Report</Button>
          </DialogPrimaryActions>
        </Dialog>
      </LayoutScreen>
    );
  return (
    <LayoutScreen sx={{ backgroundColor: "black.3" }}>
      <Stack>
        <Header title={cutureReportData.title}></Header>

        <Box
          sx={{
            width: "100%",
            backgroundColor: "var(--colors-brandDark-5)",
          }}
        >
          <Box
            sx={{
              maxWidth: "1440px",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                m: 5,
                p: 2,
              }}
            >
              <Inline alignX="apart">
                <Heading level={"5"}>{cutureReportData.campaign.name}</Heading>
                <CampaignCompletionCard campaign={cutureReportData.campaign} />
              </Inline>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "white",
              height: "54px",
              position: "absolute",
              borderBottom: "1px solid var(--colors-black-10)",
            }}
          >
            <Divider orientation={"horizontal"}></Divider>
          </Box>

          <Inline alignX="center">
            <Box>
              <Tabs id="interactive" label={""}>
                <Box sx={{ pl: 17 }}>
                  <TabsList>
                    <Tab>Report</Tab>
                    <Tab>Alignment</Tab>
                    <Tab>Subcultures</Tab>
                    <Tab>Engagement</Tab>
                    <Tab>Feedback</Tab>
                  </TabsList>
                </Box>

                <TabPanels>
                  <TabPanel>
                    <Box sx={{ width: "1440px" }}>
                      {cutureReportData.seriesA.totalResults === 0 ? (
                        <Card
                          sx={{
                            textAlign: "center",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <ErrorMessage
                            title="Nothing to see here yet!"
                            icon={<NotFoundImage />}
                            description="Looks like there is no survey data for this campaign."
                          />
                        </Card>
                      ) : (
                        <Box sx={reportCardStyle}>
                          <Box sx={titleContainerStyle}>
                            <Heading level="5">
                              Culture Overview - All Respondents{" "}
                              {formatMonth(
                                cutureReportData.campaign?.dueDate?.toString()
                              )}
                            </Heading>
                          </Box>
                          {!!cutureReportData.campaign?.milestones?.length && (
                            <EventsList
                              milestones={cutureReportData.campaign.milestones}
                            />
                          )}
                          <Flex sx={cultureDimensionsInsights}>
                            <Text size="xl" sx={{ color: "white" }}>
                              <b>Culture dimension insights</b>
                            </Text>
                          </Flex>

                          <Card sx={{ paddingTop: "0" }}>
                            <ReportGraph
                              seriesA={cutureReportData.seriesA}
                              seriesB={cutureReportData.seriesB}
                              reference={cutureReportData.reference}
                              observations={observations}
                              refDueDate={formatMonth(
                                cutureReportData.refCampaign?.dueDate?.toString()
                              )}
                            ></ReportGraph>
                          </Card>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box sx={{ width: "1440px" }}>
                      <Inline gap="5">
                        <Select
                          sx={{ ml: "20px", mt: "10px", width: "200px" }}
                          onChange={(option) => {
                            setHotspotFiltertype(option.target.value);
                            switch (option.target.value) {
                              case "divisions":
                                setAggregates(
                                  cutureReportData.hotspotData.divisionData
                                );
                                break;
                              case "officeLocation":
                                setAggregates(
                                  cutureReportData.hotspotData.locationData
                                );
                                break;
                              case "jobLevel":
                                setAggregates(
                                  cutureReportData.hotspotData.jobLevelData
                                );
                                break;

                              default:
                                break;
                            }
                          }}
                        >
                          <option value="divisions">Division</option>
                          <option value="officeLocation">Location</option>
                          <option value="jobLevel">Job Level</option>
                        </Select>

                        <Switch
                          sx={{ pt: 3 }}
                          label="Show gap (% difference)"
                          onChange={(option) => {
                            setShowValues(option.target.checked);
                          }}
                        />
                      </Inline>
                      <Heatmap
                        orgData={cutureReportData.hotspotData.orgData}
                        aggregates={aggregates}
                        showValues={showValues}
                        filterType={hotspotFilterType}
                        currentResponses={currentResponses}
                        desiredResponses={desiredResponses}
                        setDialogContent={setDialogContent}
                        dialogHeader={dialogHeader}
                        dialogOpen={dialogOpen}
                        setDialogOpen={setDialogOpen}
                      ></Heatmap>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box sx={{ width: "1440px" }}>
                      <BreakdownContent
                        filterType={filterType}
                        setFilterType={setFiltertype}
                        setAssessmentType={setAssessmentType}
                        filters={filters}
                        setFilters={setFilters}
                      ></BreakdownContent>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box sx={{ width: "1440px" }}>
                      {cutureReportData.engagementData.perceptionAverage ? (
                        <>
                          <Box sx={{ ml: 6, pt: 1, pb: 3 }}>
                            <Text>
                              This shared version of the culture report shows
                              engagement scores for the whole group only. To see
                              a breakdown please <Link href="/">log in</Link> to
                              Compono.
                            </Text>
                          </Box>
                          <EngagementContent
                            loading={false}
                            culturePerception={cutureReportData.engagementData}
                          ></EngagementContent>
                        </>
                      ) : (
                        <Card
                          sx={{
                            textAlign: "center",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <ErrorMessage
                            title="Nothing to see here!"
                            icon={<NotFoundImage />}
                            description="Looks like there is no survey data for this campaign."
                          />
                        </Card>
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box
                      sx={{
                        width: "1440px",
                      }}
                    >
                      {cutureReportData.feedbackCount === 0 ? (
                        <Card
                          sx={{
                            textAlign: "center",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <ErrorMessage
                            title="Nothing to see here!"
                            icon={<NotFoundImage />}
                            description="There is no feedback for this campaign."
                          />
                        </Card>
                      ) : (
                        <>
                          <Inline alignX="center">
                            <Text>
                              <b>
                                {cutureReportData.feedbackCount ?? 0} out of{" "}
                                {cutureReportData.campaign.responses}{" "}
                                respondents had something to say (
                                {Math.round(
                                  (100 / cutureReportData.campaign.responses) *
                                    cutureReportData.feedbackCount
                                )}
                                %)
                              </b>
                            </Text>
                          </Inline>
                          <Box
                            sx={{
                              backgroundColor: "white",
                              borderRadius: 5,
                              m: "20px",
                              p: "20px",
                            }}
                          >
                            <Inline alignX="center">
                              <Stack>
                                <FeedbackSVG></FeedbackSVG>
                                <Text size="xxl" align="center" sx={{ mt: 6 }}>
                                  Download feedback
                                </Text>
                                <Inline alignX="center">
                                  {cutureReportData.feedbackCount &&
                                    cutureReportData.feedbackCount > 0 && (
                                      <Button
                                        iconRight={CloudDownloadOutlineIcon}
                                        sx={{ width: "200px", mt: 4 }}
                                        onClick={() => {
                                          window.location =
                                            cutureReportData.feedBackLink;
                                        }}
                                      >
                                        Download CSV
                                      </Button>
                                    )}
                                </Inline>
                              </Stack>
                            </Inline>
                          </Box>
                        </>
                      )}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Inline>
        </Box>
      </Stack>
    </LayoutScreen>
  );
};

export default CultureReport;
