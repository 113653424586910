import { MainNavMenuAsButton, MainNavMenuLink } from "@compono/ui";

import { ShowIf, ShowIfAny, useOrganisation } from "~/auth-layer";

import { SettingsOutline2Icon } from "../icons/SettingsOutline2Icon";
import { useFlags } from "launchdarkly-react-client-sdk";
import { env } from "~/env";

export const AdminNav = () => {
  const { messageTemplates } = useFlags();
  const [org] = useOrganisation();
  return (
    <ShowIfAny
      allowedTo={[
        "organisations.edit",
        "employers.view",
        "teams.view",
        "jobBoards.manageSubscription",
        "organisations.manageApiKeys",
        "organisations.manageHrIntegrations",
      ]}
    >
      <MainNavMenuAsButton icon={SettingsOutline2Icon} label="Admin">
        {/* <ShowIf allowedTo="organisations.edit">
          <MainNavMenuLink href={`/setup`} label="Setup checklist" />
        </ShowIf> */}
        <ShowIf allowedTo="organisations.edit">
          <MainNavMenuLink href={`/account`} label="Organisation information" />
        </ShowIf>
        <ShowIf allowedTo="organisations.edit">
          <MainNavMenuLink href={`/settings`} label="Organisation settings" />
        </ShowIf>
        <ShowIf allowedTo="employers.view">
          <MainNavMenuLink href={`/users`} label="Users" />
        </ShowIf>
        <ShowIf allowedTo="teams.view">
          <MainNavMenuLink href={`/teams`} label="Hiring teams" />
        </ShowIf>
        <ShowIfAny allowedTo={["campaigns.view", "product.develop"]}>
          <MainNavMenuLink href={`/groups`} label="Groups" />
        </ShowIfAny>
        {messageTemplates && (
          <ShowIf allowedTo="applicationMessages.createTemplates">
            <MainNavMenuLink
              href={`/message-templates`}
              label="Message templates"
            />
          </ShowIf>
        )}
        <ShowIf allowedTo="isAbleToManageTags">
          <MainNavMenuLink href={`/manage-tags`} label="Tag library" />
        </ShowIf>
        <ShowIf allowedTo="jobBoards.manageSubscription">
          <MainNavMenuLink
            href={`/job-board-integration`}
            label="Job board integration"
          />
        </ShowIf>
        <ShowIf allowedTo="organisations.manageApiKeys">
          <MainNavMenuLink href={`/employer-api`} label="API management" />
        </ShowIf>
        <ShowIf allowedTo="organisations.manageHrIntegrations">
          <MainNavMenuLink
            href={`/integrations`}
            label="Integration management"
            id="integrations"
          />
        </ShowIf>
      </MainNavMenuAsButton>
    </ShowIfAny>
  );
};
