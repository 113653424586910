import { MainNavMenuAsButton, MainNavMenuLink } from "@compono/ui";
import { CandidatesOutlineIcon } from "../icons/CandidatesOutlineIcon";
import { ShowIf } from "../auth";
import { useOrganisation } from "~/auth-layer";
import { env } from "~/env";

export const DevelopNav = () => {
  const [org] = useOrganisation();
  return (
    <MainNavMenuAsButton
      icon={CandidatesOutlineIcon}
      label="Develop"
      data-menu-button="true"
    >
      <MainNavMenuLink href={`/courses`} label="Courses" />
      <ShowIf allowedTo="develop.learningPortal">
        <MainNavMenuLink
          href={`https://${org.id}.${env.CLUI_DOMAIN}/sso`}
          label="Learning portal"
          target="_blank"
        />
      </ShowIf>
    </MainNavMenuAsButton>
  );
};
