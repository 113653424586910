import { MainNavLink } from "@compono/ui";
import { ShowIf } from "~/auth-layer";
import { LockedSolidIcon } from "@compono/ui-icons";

export const DeniedNav = () => {
  return (
    <>
      <ShowIf
        allowedTo="product.hire"
        ifNotRender={
          <MainNavLink
            target="_blank"
            href="https://www.compono.com/products/hire"
            icon={LockedSolidIcon}
            label="Hire"
          />
        }
        children={null}
      />
      <ShowIf
        allowedTo="product.engage"
        ifNotRender={
          <MainNavLink
            target="_blank"
            href="https://www.compono.com/products/engage"
            icon={LockedSolidIcon}
            label="Engage"
          />
        }
        children={null}
      />
      <ShowIf
        allowedTo="product.develop"
        ifNotRender={
          <MainNavLink
            target="_blank"
            href="https://www.compono.com/products/develop"
            icon={LockedSolidIcon}
            label="Develop"
          />
        }
        children={null}
      />
    </>
  );
};
